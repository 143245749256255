import React from "react";

const MessageModal = ({ type, message, onClose, onSuccess }) => {
  const handleAccept = () => {
    if (type === "success" && onSuccess) {
      onSuccess();
    } else {
      onClose();
    }
  };

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          padding: "20px",
          borderRadius: "10px",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          maxWidth: "400px",
          textAlign: "center",
        }}
      >
        <div>
          {type === "success" ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              style={{
                width: "80px",
                height: "80px",
                marginBottom: "20px",
                color: "#4CAF50",
              }}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 12.75l6 6 9-13.5"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              style={{
                width: "80px",
                height: "80px",
                marginBottom: "20px",
                color: "#f44336",
              }}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          )}
        </div>
        <h2>{type === "success" ? "¡Éxito!" : "Error"}</h2>
        <p>{message}</p>
        <button
          onClick={handleAccept}
          style={{
            marginTop: "20px",
            padding: "10px 20px",
            backgroundColor: type === "success" ? "#4CAF50" : "#f44336",
            color: "white",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          Aceptar
        </button>
      </div>
    </div>
  );
};

export default MessageModal;

