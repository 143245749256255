// ProfessionalInfo.jsx
import React from 'react';
import styled from 'styled-components';
import { FaStethoscope } from 'react-icons/fa';
const Container = styled.section`
  width: 90%;
  max-width: 1100px;
  margin: 2rem auto;
  background-color: #FAF6E3; /* Fondo claro */
  border-radius: 10px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  padding: 2rem;
  font-family: 'Roboto', sans-serif;
  color: #2A3663; /* Texto en tono oscuro */
`;

const Title = styled.h1`
  font-family: 'Oswald', sans-serif;
  text-align: center;
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #2A3663;
  border-bottom: 2px solid #2A3663;
  padding-bottom: 0.5rem;
  letter-spacing: 1px;
`;

const SubTitle = styled.h2`
  margin: 1.5rem 0 0.5rem 0;
  font-size: 1.5rem;
  color: #2A3663;
  font-family: 'Oswald', sans-serif;
  text-transform: uppercase;
`;

const Paragraph = styled.p`
  margin: 0.2rem 0;
  line-height: 1.6;
  font-size: 1rem;
  color: #2A3663;
`;

const List = styled.ul`
  list-style: none;
  padding-left: 1rem;
  margin-top: 0.5rem;
`;

const ListItem = styled.li`
  margin: 0.3rem 0;
  position: relative;
  padding-left: 1.2rem;

  &::before {
    content: '•';
    position: absolute;
    left: 0;
    color: #B59F78;
    font-size: 1.2rem;
    line-height: 1.2rem;
  }
`;

const Highlight = styled.span`
  font-weight: bold;
  color: #B59F78;
`;

const ProfessionalInfo = () => {
  return (
    <Container>
      <Title><FaStethoscope style={{ marginRight: '8px' }} />Experiencia Profesional</Title>

      {/* Formación Académica */}
      <SubTitle>Formación Académica</SubTitle>
      <Paragraph>
        <Highlight>Médico Cirujano y Partero (1993 - 2000)</Highlight> <br/>
        Universidad Popular Autónoma del Estado de Puebla (UPAEP) | Con título profesional.
      </Paragraph>
      <Paragraph>
        <Highlight>Especialidad en Psiquiatría (2006 - 2010)</Highlight> <br/>
        Hospital Psiquiátrico Dr. Rafael Serrano Batán<br/>
        Benemérita Universidad Autónoma de Puebla (BUAP) | Con título de especialista.
      </Paragraph>
      <Paragraph>
        <Highlight>Maestría en Filosofía</Highlight> <br/>
        Benemérita Universidad Autónoma de Puebla (BUAP)<br/>
        Estudios en Filosofía Hermenéutica en Psicopatología | Con título profesional.
      </Paragraph>
      <Paragraph>
        <Highlight>Maestría en Bases Biológicas de la Conducta (2023)</Highlight> <br/>
        Con constancia de estudios.
      </Paragraph>

      {/* Trayectoria Profesional */}
      <SubTitle>Trayectoria Profesional</SubTitle>
      <Paragraph>
        <Highlight>Médico Especialista en Psiquiatría (2009 - 2019)</Highlight><br/>
        Secretaría de Salud del Estado de Puebla (SSEP) <br/>
        - Atención en consulta externa (adicciones, psiquiatría general y casos legales).<br/>
        - Función de Perito Oficial del Estado en psiquiatría.
      </Paragraph>
      <Paragraph>
        <Highlight>Psiquiatra en el Centro de Reinserción Social de San Pedro Cholula (2009 - 2021)</Highlight><br/>
        - Atención integral a personas privadas de la libertad, con énfasis en salud mental y rehabilitación.
      </Paragraph>
      <Paragraph>
        <Highlight>Médico Psiquiatra en la Práctica Privada (2010 - Actualidad)</Highlight><br/>
        - Abordaje de diversos trastornos psiquiátricos.<br/>
        - Realización de peritajes legales de manera independiente.
      </Paragraph>
      <Paragraph>
        <Highlight>Médico de Urgencias Psiquiátricas</Highlight><br/>
        Christus Muguerza Hospitales | Actualidad<br/>
        - Manejo de emergencias psiquiátricas y atención de crisis.
      </Paragraph>

      {/* Áreas de Especialización */}
      <SubTitle>Áreas de Especialización</SubTitle>
      <List>
        <ListItem>Psiquiatría clínica</ListItem>
        <ListItem>Peritajes legales en psiquiatría</ListItem>
        <ListItem>Manejo de adicciones</ListItem>
        <ListItem>Urgencias psiquiátricas</ListItem>
        <ListItem>Filosofía hermenéutica aplicada a la psicopatología</ListItem>
      </List>
    </Container>
  );
};

export default ProfessionalInfo;
