import moment from 'moment';
import {AreDifferentDays} from "../helpers/helpers"
export function scheduleBuilder(events) {
    // Fecha de inicio: hoy, al inicio del día en UTC
    const startDate = moment().utc().startOf('day');
    // Fecha final: dos meses a partir de hoy, al final del día en UTC
    const endDate = moment().utc().add(2, 'months').endOf('day');

    const schedule = [];
    var array=[];
    let currentDate = startDate.clone();

    const noDisponibleConfig = [
        { startHour: 17, endHour: 24, endMinute: 0 }, // Domingo
        { startHour: 17, endHour: 21, endMinute: 0 }, // Lunes, Miercoles, Viernes
        { startHour: 20, endHour: 23, endMinute: 0 },    // Martes, Jueves
        { startHour: 18, endHour: 21, endMinute: 0 } //Sabado
        // Agregar más días según sea necesario
    ];
    const dayBefore = startDate.clone().subtract(1, 'days');
    const noDispStart = dayBefore.clone().set({ hour: 17, minute: 0, second: 0 });
    // Para las 00:00 de ese "mismo" día en UTC, hay que subir un día más
    // o simplemente usar hour: 24 en el mismo dayBefore
    const noDispEnd = dayBefore.clone().set({ hour: 24, minute: 0, second: 0 });

    // 3) Agregamos el evento "No Disponible"
    schedule.push({
        title: 'No Disponible',
        start: noDispStart.toDate(),
        end: noDispEnd.toDate(),
        type: 'red'
    });
    // Recorrer día a día hasta la fecha final
    while (currentDate.isSameOrBefore(endDate)) {
        // Filtramos los eventos existentes para la fecha actual en UTC
        const eventsForDay = events.filter(event => {
            const eventStart = moment.utc(event.start); // Interpretamos los eventos en UTC
            return eventStart.isSame(currentDate, 'day');
        });
        if (currentDate.day() === 0) {
            // Si es domingo, creamos un evento "No Disponible" de 10am a 18pm en UTC.
            const startNoDisponible = currentDate.clone().set({ hour: noDisponibleConfig[0].startHour, minute: 0, second: 0 });
            const endNoDisponible = currentDate.clone().set({ hour: noDisponibleConfig[0].endHour, minute: 0, second: 0 });

            schedule.push({
                title: 'No Disponible',
                start: startNoDisponible.toDate(), // Convertido a objeto Date
                end: endNoDisponible.toDate(),     // Convertido a objeto Date
                type: 'red'
            });
        } else {
            let selected;
            switch (currentDate.day()) {
                case 1:
                case 3:
                case 5:
                    selected = 1;
                    
                    break;
                case 2:
                case 4:
                    selected = 2;
                    break;
                default:
                    selected = 3;
            }
            for (let hour = noDisponibleConfig[selected].startHour; hour <= noDisponibleConfig[selected].endHour; hour++) {
                // 1) Definimos el rango de la primera media hora
                let auxStart1 = currentDate.clone().set({ hour: hour, minute: 0, second: 0 });
                let auxEnd1 = currentDate.clone().set({ hour: hour, minute: 29, second: 59 });

                // 2) Definimos el rango de la segunda media hora
                let auxStart2 = currentDate.clone().set({ hour: hour, minute: 30, second: 0 });
                let auxEnd2 = currentDate.clone().set({ hour: hour, minute: 59, second: 59 });

                // 3) Checamos si hay eventos en la PRIMERA media hora (XX:00 - XX:29)
                const hasEventFirstHalf = eventsForDay.some(event => {
                    const eventStart = moment(event.start);
                    const eventEnd = moment(event.end);   // Ajusta según tu código (start o end).
                    

                    // Si el inicio del evento está entre [auxStart1, auxEnd1]
                    if (eventStart.isBetween(auxStart1, auxEnd1, null, '[)')) {
                        return true;
                    }
                    // O si el final del evento está entre [auxStart1, auxEnd1]
                    if (eventEnd.isBetween(auxStart1, auxEnd1, null, '[)')) {
                        return true;
                    }
                    return false;
                });

                // 4) Checamos si hay eventos en la SEGUNDA media hora (XX:30 - XX:59)
                const hasEventSecondHalf = eventsForDay.some(event => {
                    const eventStart = moment(event.start);
                    const eventEnd = moment(event.end);

                    // Si el inicio del evento está entre [auxStart2, auxEnd2]
                    if (eventStart.isBetween(auxStart2, auxEnd2, null, '[)')) {
                        return true;
                    }
                    // O si el final del evento está entre [auxStart2, auxEnd2]
                    if (eventEnd.isBetween(auxStart2, auxEnd2, null, '[)')) {
                        return true;
                    }
                    return false;
                });

                array.push({event:hasEventFirstHalf,start:auxStart1,end:auxEnd1});
                array.push({event:hasEventSecondHalf,start:auxStart2,end:auxEnd2});
            }
            if (selected === 1 && currentDate.day() !== 0) {
                schedule.push({
                    title: 'No Disponible',
                    // Ajustamos el tiempo a UTC-6 (México) y establecemos las horas de inicio y fin
                    start: currentDate.clone()
                        .set({ hour: 22, minute: 0, second: 0, millisecond: 0 }) // 16:00 en México
                        .utc() // Convertir de vuelta a UTC
                        .toDate(),
                    end: currentDate.clone().add(1,"day")
                        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 }) // 18:00 en México
                        .utc() // Convertir de vuelta a UTC
                        .toDate(),
                    type: 'red'
                    
                });
            }else
            if (selected === 2 && currentDate.day() !== 0) {
                schedule.push({
                    title: 'No Disponible',
                    // Ajustamos el tiempo a UTC-6 (México) y establecemos las horas de inicio y fin
                    start: currentDate.clone()
                        .set({ hour: 17, minute: 0, second: 0, millisecond: 0 }) // 16:00 en México
                        .utc() // Convertir de vuelta a UTC
                        .toDate(),
                    end: currentDate.clone()
                        .set({ hour: 20, minute: 0, second: 0, millisecond: 0 }) // 18:00 en México
                        .utc() // Convertir de vuelta a UTC
                        .toDate(),
                    type: 'red'
                    
                });
            }else if (selected === 3 && currentDate.day() !== 0) {
                schedule.push({
                    title: 'No Disponible',
                    // Ajustamos el tiempo a UTC-6 (México) y establecemos las horas de inicio y fin
                    start: currentDate.clone()
                        .set({ hour: 17, minute: 0, second: 0, millisecond: 0 }) // 16:00 en México
                        .utc() // Convertir de vuelta a UTC
                        .toDate(),
                    end: currentDate.clone()
                        .set({ hour: 18, minute: 0, second: 0, millisecond: 0 }) // 18:00 en México
                        .utc() // Convertir de vuelta a UTC
                        .toDate(),
                    type: 'red'
                    
                });
                schedule.push({
                    title: 'No Disponible',
                    // Ajustamos el tiempo a UTC-6 (México) y establecemos las horas de inicio y fin
                    start: currentDate.clone()
                        .set({ hour: 22, minute: 0, second: 0, millisecond: 0 }) // 16:00 en México
                        .utc() // Convertir de vuelta a UTC
                        .toDate(),
                    end: currentDate.clone().add(1,"day")
                        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 }) // 18:00 en México
                        .utc() // Convertir de vuelta a UTC
                        .toDate(),
                    type: 'red'
                    
                });
            }
        }
        currentDate.add(1, 'days');
    }
    let i = 0; // Asegúrate de que i esté inicializado
    while (i < array.length) {
        if (array[i].event === true) {
            let j = i; // Comienza a buscar desde el índice actual
            while (j < array.length) {
                // Verifica límites antes de acceder a `array[j+1]`
                if (j + 1 >= array.length || array[j + 1].event === false || AreDifferentDays(array[i].start,array[j+1].start)) {
                    schedule.push({
                        title: 'No Disponible',
                        start: array[i].start.toDate(), // Asegúrate de que `start` tenga el método toDate()
                        end: array[j].end.toDate(),     // Asegúrate de que `end` tenga el método toDate()
                        type: 'red'
                    });
                    i = j + 1; // Avanza el índice principal después del rango procesado
                    break; // Sal del bucle interno
                }
                j++;
            }
        } else {
            let j = i; // Comienza a buscar desde el índice actual
            while (j < array.length) {
                if (j + 1 >= array.length || array[j + 1].event === true || AreDifferentDays(array[i].start,array[j+1].start)) {
                    console.log("start",array[i].start)
                    console.log("end",array[j].end)
                    if (array[i + 1].event === true) {
                        schedule.push({
                            title: 'Solo subsecuente',
                            start: array[i].start.toDate(), // Asegúrate de que `start` tenga el método toDate()
                            end: array[j].end.toDate(),     // Asegúrate de que `end` tenga el método toDate()
                            type: 'orange'
                        });
                        i = j ;
                        break;
                    }
                    //console.log(j + 1 >= array.length,array[j + 1].event === true,AreDifferentDays(array[i].start,array[j].start))
                    schedule.push({
                        title: 'Disponible primera vez y subsecuente',
                        start: array[i].start.toDate(), // Asegúrate de que `start` tenga el método toDate()
                        end: array[j].end.toDate(),     // Asegúrate de que `end` tenga el método toDate()
                        type: 'green'
                    });
                    i = j ;
                    //i = j + 1; // Avanza el índice principal después del rango procesado
                    break; // Sal del bucle interno
                }
                j++;
            }
            i++
        }
    }

    //console.log(schedule)
    // Retorna el calendario generado
    return schedule;
}
