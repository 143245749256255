import moment from 'moment';

/**
 * Checks if two dates are on different days.
 * @param {moment.Moment} date1 - The first date in UTC format.
 * @param {moment.Moment} date2 - The second date in UTC format.
 * @returns {boolean} - Returns true if the dates are on different days, false if they are on the same day.
 * @throws {Error} - Throws an error if either parameter is not a moment object.
 */
const AreDifferentDays = (date1, date2) => {
  // Validate that both parameters are moment objects
  if (!moment.isMoment(date1) || !moment.isMoment(date2)) {
    throw new Error('Both parameters must be moment objects.');
  }

  // Compare the dates based on the day, considering UTC
  const sameDay = date1.utc().isSame(date2.utc(), 'day');

  // Return true if not the same day, otherwise false
  return !sameDay;
};

export { AreDifferentDays };
