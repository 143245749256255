import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/es'; // Importar localización en español
import 'react-big-calendar/lib/css/react-big-calendar.css';
import styles from './newapp.module.css'; // Asegúrate de crear este archivo CSS
import { scheduleBuilder } from '../../helpers/builder';
import styled from 'styled-components';


import { uri } from '../../helpers/url'
import MessageModal from "../../components/resultado/resultado";

const Container = styled.div`
  height: 90vh;
  width: 100vw;
  .rbc-event{
    width: 100% !important;   
    border-radius: 0px;
    border: none;
  }
  .rbc-time-content{
    overflow: hidden;
  }
  .rbc-events-container{
    margin-right:0;
  }
  .rbc-current-time-indicator{
    display: none;
  }

  @media (min-width: 768px) {
    width: 65vw;
    height: 70vh;
  }
`;


const Create = () => {

  function filtrarCitas(citas) {
    const hoy = moment().startOf('day'); // Fecha actual al inicio del día
    const dosMesesDespues = moment().add(2, 'months').endOf('day'); // Fecha de 2 meses en adelante

    return citas.filter(cita => {
      const fechaCita = moment(cita.start);
      return fechaCita.isBetween(hoy, dosMesesDespues, null, '[]'); // Incluye las citas de hoy y hasta 2 meses
    });
  }

  const [horario, setHorario] = useState([])


  moment.locale('es');
  const localizer = momentLocalizer(moment);
  const messages = {
    allDay: 'Todo el día',
    previous: 'Atrás',
    next: 'Siguiente',
    today: 'Hoy',
    month: 'Mes',
    week: 'Semana',
    day: 'Día',
    agenda: 'Agenda',
    date: 'Fecha',
    time: 'Hora',
    event: 'Evento',
    noEventsInRange: 'No hay eventos en este rango',
    showMore: total => `+ Ver más (${total})`
  };
  const minTime = new Date();
  minTime.setHours(11, 0, 0);

  const maxTime = new Date();
  maxTime.setHours(18, 0, 0);


  const [events, setEvents] = useState();
  const [view, setView] = useState('day');
  const CustomToolbar = ({ label, onNavigate, onView }) => {
    const capitalizeDay = (label) => {
      const [day, ...rest] = label.split(' ');
      return [day.charAt(0).toUpperCase() + day.slice(1), ...rest].join(' ');
    };
    return (
      <div className={styles.customtoolbar}>
        <span className="rbc-btn-group">
          <button type="button" className={styles.custombutton} onClick={() => onNavigate('PREV')}>
            Atrás
          </button>
          <button type="button" className={styles.custombutton} onClick={() => onNavigate('TODAY')}>
            Hoy
          </button>
          <button type="button" className={styles.custombutton} onClick={() => onNavigate('NEXT')}>
            Siguiente
          </button>
        </span>
        <span className={styles.customtoolbarlabel}>{capitalizeDay(label)}</span>
        <span className="rbc-btn-group">
          <button type="button" className={styles.custombutton} onClick={() => onView('week')}>
            Semana
          </button>
          <button type="button" className={styles.custombutton} onClick={() => onView('day')}>
            Día
          </button>
          {/* <button type="button" className={styles.custombutton} onClick={() => onView('agenda')}>
            Agenda
          </button> */}
        </span>
      </div>
    );
  };


  const CustomEvent = ({ event, title, view }) => {
    const [isPC, setIsPC] = useState(window.innerWidth > 768); // Ajuste basado en el ancho de pantalla

    useEffect(() => {
      const handleResize = () => {
        setIsPC(window.innerWidth > 768);
      };

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    const eventStyle = {
      borderRadius: '4px',
      border: 'none',
      display: isPC ? 'flex' : 'flex', // Si es PC, display es flex, de lo contrario none
      alignItems: 'center',
      height: '100%',
    };

    if (view === 'week') {
      console.log(view);
      return (
        <div style={eventStyle}>
          {title}
        </div>
      );
    }

    return (
      <div style={eventStyle}>
        {title}
      </div>
    );
  };

  const eventPropGetter = (event) => {
    let backgroundColor = '';
    let fontSize = '';
    switch (event.type) {
      case 'red':
        backgroundColor = 'red';
        fontSize = "22px";
        break;
      case 'green':
        backgroundColor = 'green';
        break;
      case 'orange':
        backgroundColor = 'orange';
        break;
      default:
        backgroundColor = 'gray';
    }
    return { style: { backgroundColor, fontSize,   } };

  };


  useEffect(() => {
    const fetchData = async (enlace) => {
      try {
        const response = await fetch(enlace, {
          method: 'GET',
        });

        if (!response.ok) {
          throw new Error('Error de conexión. Asegúrate de que el servidor esté en ejecución.');
        }

        const jsonData = await response.json();

        if (jsonData.error) {
          throw new Error(jsonData.error);
        } else {
          console.log(jsonData);
          const result = scheduleBuilder(filtrarCitas(jsonData));

          // Si quieres actualizar el estado con este resultado
          setEvents(result);
          setHorario(result)

        }
      } catch (error) {
        console.log('Connection error');
        console.error('Error fetching data:', error);
      }
    };

    fetchData(`${uri()}dates`);
  }, []);


  //Solo permite isertar numeros en el campo de telefono
  const [telefono, setTelefono] = useState("");

  const handleChange = (e) => {
    const valor = e.target.value;
    // Solo permite números
    if (/^\d*$/.test(valor)) {
      setTelefono(valor);
    }
  };


  //Solo permite isertar numeros en el campo de telefono
  const [edad, setEdad] = useState("");

  const handleChangeedad = (e) => {
    const valor = e.target.value;

    // Solo permite números de hasta 2 dígitos
    if (/^\d{0,2}$/.test(valor)) {
      setEdad(valor);
    }
  };

  const [fechaHoy] = useState(() => moment().format('YYYY-MM-DD'));

  const [tipoCita, setTipoCita] = useState("");
  const [horasDisponibles, setHorasDisponibles] = useState([]);
  const [intervalo, setIntervalo] = useState(0)
  const [diaSeleccionado, setDiaSeleccionado] = useState(null); // Cambiado a null
  const [horaInicio, setHoraInicio] = useState('')
  const [horaFin, setHoraFin] = useState('');


  const handleTipoCitaChange = (e) => {
    setTipoCita(e.target.value);
    if (diaSeleccionado) {
      generarHorariosDisponibles(e.target.value, diaSeleccionado);

      setHoraInicio("")
      setHoraFin("")
    }
  };

  const handleDiaChange = (e) => {
    setHoraInicio("")
    setHoraFin("")
    const dia = moment(e.target.value);
    console.log("Dia seleccionado: ", dia)
    console.log("Dia usando moment:", moment(dia).get('date'))
    const aux = moment(dia).toISOString();
    console.log("Probando algo: ", moment(aux))
    setDiaSeleccionado(dia);
    if (tipoCita) {
      generarHorariosDisponibles(tipoCita, dia);
    }
  };

  const generarHorariosDisponibles = (tipoCita, dia) => {
    const intervalo = tipoCita === "consecuente" ? 59 : 29;
    setIntervalo(intervalo)
    console.log("Intervalo seleccionado: ", intervalo)
    console.log("El dia es: ", dia)
    // Filtrar eventos disponibles para el día seleccionado
    const horarioDisponible = horario.filter(evento =>
      moment(evento.start).isSame(dia, 'day') && evento.type !== 'red'
    );

    console.log("Horario disponible: ", horarioDisponible)
    let horasDisponibles = [];

    // Recorremos los eventos disponibles
    horarioDisponible.forEach(evento => {
      let start = moment(evento.start).second(59).millisecond(99);
      let end = moment(evento.end).second(59).millisecond(99);

      // Generar las horas dentro del rango del evento según el intervalo
      while (start.isBefore(end)) {
        // Verificar si el intervalo cabe completamente dentro del rango
        if (start.clone().add(intervalo, 'minutes').isSameOrBefore(end)) {
          horasDisponibles.push(start.format('YYYY-MM-DDTHH:mm'));
        }
        
        if(intervalo === 29){
          start.add(intervalo+1, 'minutes');
        } else {
          start.add(intervalo, 'minutes');
        }
        
      }
    });

    setHorasDisponibles(horasDisponibles);
  };


  // Manejar cambios en el select de hora de inicio
  const handleHoraInicioChange = (e) => {
    const horaSeleccionada = e.target.value;
    setHoraInicio(horaSeleccionada);
    setHoraFin(moment(horaSeleccionada).add(intervalo, 'minutes').format("h:mm A"));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Crear un objeto JSON directamente desde el formulario
    const form = e.target;
    const json = Array.from(form.elements)
      .filter((element) => element.name) // Filtrar solo los elementos con el atributo `name`
      .reduce((acc, element) => {
        acc[element.name] = element.value;
        return acc;
      }, {});

    // Modificaciones específicas al JSON
    delete json.day;
    delete json.tipo;
    delete json.finish
    json.tipo = tipoCita;
    json.end = moment(horaInicio).add(intervalo, 'minutes').format('YYYY-MM-DDTHH:mm');
    json.notas = "Sin notas adicionales.";

    if (json.tipo === "consecuente") {
      json.color = "#3498DB";
      // json.colorName = "Azul Brillante";
    } else {
      json.color = "#FF5733";
      // json.colorName = "Rojo Anaranjado";
    }


    console.log(json); // Muestra el objeto JSON en la consola

    fetch(uri() + 'api/add', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(json)
    })
      .then(response => {
        // if (!response.ok) {
        //   throw new Error('Error de conexión. Asegúrate de que el servidor esté en ejecución.');
        // }
        return response.json();
      })
      .then(data => {
        console.log(data)
        if (data.error) {
          setModalConfig({
            type: "error",
            message: data.error,
          });
          setShowModal(true);
          throw new Error(data.error);
        } else {
          console.log(data);
          setModalConfig({
            type: "success",
            message: data.message+"\nSu folio es: "+data.id+"\nComuniquese via WhatsApp a 222 325 1922",
          });
          setShowModal(true);
        }
      })
      .catch(error => {
        console.error(error);
      });
  };


  const [showModal, setShowModal] = useState(false);
  const [modalConfig, setModalConfig] = useState({ type: "", message: "" });



  return (
    <div className={styles.content}>
      <header className={styles.header}>
        <h1>Agendar Consulta</h1>

        <p>Si es tu primera consulta, selecciona un horario disponible de al menos una hora.</p>
        <p>Si es una consulta subsecuente, con media hora es suficiente.</p>
      </header>
      <div className={styles.middle}>

        <section className={styles.calendarSection}>
          <Container>
          <Calendar
            localizer={localizer}
            events={events}
            min={minTime}
            max={maxTime}
            messages={messages}
            views={['week', 'day', 'agenda']}
            defaultView='day'
            onView={(view) => setView(view)}
            eventPropGetter={eventPropGetter}
            components={{
              toolbar: CustomToolbar,
              event: (props) => <CustomEvent   {...props} view={view} />,
            }}
          />
          </Container>

          <div className={styles.indicaters}>
            <div>
              <div className={`${styles.ball} ${styles.notAvailable}`}></div>
              <p>No disponible</p>
            </div>
            <div>
              <div className={`${styles.ball} ${styles.consequent}`}></div>
              <p>Primera vez o subsecuente</p>
            </div>
            <div>
              <div className={`${styles.ball} ${styles.subsequent}`}></div>
              <p>Solo subsecuente</p>
            </div>

          </div>
        </section>
        <section className={styles.formulario}>
          <h1>Rellena tu información</h1>
          <form onSubmit={handleSubmit}>

            <label htmlFor="nombre">Nombre completo:</label>
            <input type="text" id="nombre" name="nombre" required />


            <label htmlFor="edad">Edad:</label>
            <input
              type="text"
              id="edad"
              name="edad"
              required
              value={edad}
              onChange={handleChangeedad}
            />

            <label htmlFor="numero">Numero de telefono:</label>
            <input
              type="text"
              id="numero"
              name="numero"
              required
              value={telefono}
              onChange={handleChange}
            />

            <label>Tipo de cita:</label>
            <div>
              <input
                type="radio"
                id="consecuente"
                name="tipo"
                value="consecuente"
                onChange={handleTipoCitaChange}
                checked={tipoCita === "consecuente"} // Se asegura de que el valor se mantenga sincronizado
                required
              />
              <label htmlFor="consecuente">Primera vez</label>

              <input
                type="radio"
                id="subsecuente"
                name="tipo"
                value="subsecuente"
                onChange={handleTipoCitaChange}
                checked={tipoCita === "subsecuente"} // Lo mismo para el otro input
                required
              />
              <label htmlFor="subsecuente">Subsecuente</label>
            </div>

            <label htmlFor="day">Día de la cita:</label>
            <input
              type="date"
              id="day"
              name="day"
              required
              min={fechaHoy} // Fecha mínima establecida usando moment()
              onChange={handleDiaChange}
            />

            <label htmlFor="start">Seleccione una hora de inicio</label>
            <select
              name="start"
              id="start"
              disabled={!diaSeleccionado || !tipoCita}
              onChange={handleHoraInicioChange}
              value={horaInicio} // Controla el valor seleccionado con el estado
            >
              <option value="" disabled>
                Seleccione una hora
              </option>
              {horasDisponibles.map((hora) => (
                <option key={hora} value={hora}>
                  {moment(hora).format('hh:mm A')}
                </option>
              ))}
            </select>


            <label htmlFor="finish">Hora de fin:</label>
            <input
              type="text"
              id="finish"
              name="finish"
              value={horaFin}
              disabled
            />
            <button type="submit">Agendar cita</button>
          </form>
        </section>
      </div>
      {showModal && (
        <MessageModal
          type={modalConfig.type}
          message={modalConfig.message}
          onClose={() => setShowModal(false)}
          onSuccess={() => window.location.reload()}
        />
      )}
    </div>
  );
};






export default Create;
