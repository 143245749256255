function habiles() {
    return [
        //Lunes
        {
          start: '10:00', end: '14:30'
        },
        //Martes
        {
          start: '14:00', end: '17:30'
        },
        //Miercoles
        { start: '10:00', end: '14:30' },
        //Jueves
        { start: '14:00', end: '17:30' },
        //Viernes
        { start: '10:00', end: '14:30' },
        //Sabado
        { start: '12:00', end: '13:30' }
      ];
  
}



function adminHabiles() {
  return [
      //Lunes
      {
        start: '8:00', end: '20:00'
      },
      //Martes
      {
        start: '8:00', end: '20:00'
      },
      //Miercoles
      { start: '8:00', end: '20:00' },
      //Jueves
      { start: '8:00', end: '20:00' },
      //Viernes
      { start: '8:00', end: '20:00' },
      //Sabado
      { start: '8:00', end: '20:00' }
    ];

}

export {
  habiles,
  adminHabiles
}