import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/home/Home';
import Create from './pages/usernew/create';
import Admin from './pages/admin/admin'
import  Editar from './components/formulario/formulario'
import  Nuevo from './pages/adminnew/nuevo'


function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/agendar" element={<Create />} />
          <Route path="/api/*" element={<ApiRoutes/>}/>
        </Routes>
      </div>
    </Router>
  );
}

function ApiRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Admin />} />
      <Route path="editar" element={<Editar />} />
      <Route path="nuevo" element={<Nuevo />} />
    </Routes>
  );
}

export default App;