import React, { useEffect, useState } from 'react';
import styles from './home.module.css';
import styled from 'styled-components';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/es'; // Importar localización en español
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { scheduleBuilder } from '../../helpers/builder';
import { Link } from 'react-router-dom';
import ProfessionalInfo from './info';
//Informacion
import Buttons from './buttons';
import { uri } from '../../helpers/url';
import { FaArrowLeft, FaArrowRight, FaCalendarDay } from 'react-icons/fa';
const Container = styled.div`
  height: 95vh;
  width: 100vw;
  .rbc-event {
    width: 100% !important;
    border-radius: 0px;
    border: none;
  }
  .rbc-time-content {
    overflow: hidden;
  }
  .rbc-events-container {
    margin-right: 0;
  }
  .rbc-current-time-indicator {
    display: none;
  }
`;

const Home = () => {
  function filtrarCitas(citas) {
    const hoy = moment().startOf('day'); // Fecha actual al inicio del día
    const dosMesesDespues = moment().add(2, 'months').endOf('day'); // Fecha 2 meses después

    return citas.filter((cita) => {
      const fechaCita = moment(cita.start);
      return fechaCita.isBetween(hoy, dosMesesDespues, null, '[]'); 
    });
  }

  moment.locale('es');
  const localizer = momentLocalizer(moment);
  const messages = {
    allDay: 'Todo el día',
    previous: 'Atrás',
    next: 'Siguiente',
    today: 'Hoy',
    month: 'Mes',
    week: 'Semana',
    day: 'Día',
    agenda: 'Agenda',
    date: 'Fecha',
    time: 'Hora',
    event: 'Evento',
    noEventsInRange: 'No hay eventos en este rango',
    showMore: (total) => `+ Ver más (${total})`,
  };

  const minTime = new Date();
  minTime.setHours(11, 0, 0);

  const maxTime = new Date();
  maxTime.setHours(18, 0, 0);

  const [events, setEvents] = useState([]);
  const [view, setView] = useState('day');

  const CustomToolbar = ({ label, onNavigate, onView }) => {
    const capitalizeDay = (label) => {
      const [day, ...rest] = label.split(' ');
      return [day.charAt(0).toUpperCase() + day.slice(1), ...rest].join(' ');
    };

    return (
      <div className={styles.customtoolbar}>
        
        <span className={styles.customtoolbarlabel}>{capitalizeDay(label)}</span>
        <span className="rbc-btn-group">
        <button
          type="button"
          className={styles.custombutton}
          onClick={() => onNavigate('PREV')}
        >
          <FaArrowLeft /> Atrás
        </button>
        <button
          type="button"
          className={styles.custombutton}
          onClick={() => onNavigate('TODAY')}
        >
          <FaCalendarDay /> Hoy
        </button>
        <button
          type="button"
          className={styles.custombutton}
          onClick={() => onNavigate('NEXT')}
        >
          Siguiente <FaArrowRight />
        </button>
        </span>
      </div>
    );
  };

  const CustomEvent = ({ event, title, view }) => {
    const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 1000);

    useEffect(() => {
      const handleResize = () => {
        setIsLargeScreen(window.innerWidth > 1000);
      };

      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    if (view === 'week') {
      return <div>{isLargeScreen ? title : null}</div>;
    }

    return <div>{title}</div>;
  };

  const eventPropGetter = (event) => {
    let backgroundColor = '';
    let fontSize = '';
    switch (event.type) {
      case 'red':
        backgroundColor = 'red';
        fontSize = '22px';
        break;
      case 'green':
        backgroundColor = 'green';
        break;
      case 'orange':
        backgroundColor = 'orange';
        break;
      default:
        backgroundColor = 'gray';
    }
    return { style: { backgroundColor, fontSize } };
  };

  useEffect(() => {
    const fetchData = async (enlace) => {
      try {
        const response = await fetch(enlace, {
          method: 'GET',
        });

        if (!response.ok) {
          throw new Error(
            'Error de conexión. Asegúrate de que el servidor esté en ejecución.'
          );
        }

        const jsonData = await response.json();

        if (jsonData.error) {
          throw new Error(jsonData.error);
        } else {
          setEvents(scheduleBuilder(jsonData));
        }
      } catch (error) {
        console.log('Connection error');
        console.error('Error fetching data:', error);
      }
    };

    fetchData(`${uri()}dates`);
  }, []);

  return (
    <div className={styles.content}>
      {/* HEADER */}
      <header className={styles.header}>
        <div className={styles.info}>
          <a href="tel:2223251922">
            <img
              src={`${process.env.PUBLIC_URL}/icon.png`}
              alt="icono de teléfono"
              className={styles.icon}
            />
          </a>
          <a href="tel:2223251922">(222)3251299</a>
          <a href="https://wa.me/2223251922">
            <img
              src={`${process.env.PUBLIC_URL}/whatsapp.webp`}
              alt="icono de Whatsapp"
              className={styles.icon}
            />
          </a>
        </div>
        <p>PSIQUIATRA</p>
        <div className={styles.title}>
          <h1>VERÓNICA FUNES PAZ</h1>
          <p>Ciudad de Puebla-México</p>
        </div>
      </header>

      {/* SECCIÓN 1 - PRESENTACIÓN */}
      <section className={styles.s1}>
        <img
          src={`${process.env.PUBLIC_URL}/dra1.webp`}
          alt="Dra. Verónica Funes Paz"
          className={styles.draImg}
        />
        <div>
          <h2>Dra. Verónica Funes Paz</h2>
          <p>
            Doctora capacitada profesionalmente para brindar una solución a los
            síntomas de los diversos padecimientos mentales (Depresión,
            ansiedad, insomnio etc.), estos padecimientos son tratados con altos
            índices de control a través de la psiquiatría, rama de la medicina
            que se encarga de la salud de esta área.
          </p>
          <h2>
            <span>18</span> años de experiencia
          </h2>
          <p>⚫ Cédula Profesional psiquiatra: 6745401</p>
          <p>⚫ Cédula Profesional de medicina: 2916858</p>
        </div>
      </section>

      {/* SECCIÓN 2 - ESPECIALIDADES */}
      <section className={styles.s2}>
      <div>
        <h2>Experta en:</h2>
        <p>• Trastorno Depresivo Mayor</p>
        <p>• Trastorno de Ansiedad Generalizada (TAG)</p>
        <p>• Trastorno de Pánico</p>
        <p>• Trastorno Obsesivo-Compulsivo</p>
        <p>• Trastorno de Estrés Postraumático</p>
        <p>• Trastorno Bipolar</p>
        <p>• Trastorno por Déficit de Atención e Hiperactividad</p>
      </div>

        <img
          src={`${process.env.PUBLIC_URL}/dra2.webp`}
          alt="Dra. Verónica Funes Paz en consulta"
          className={styles.draImg}
        />
      </section>

      {/* SECCIÓN 3 - SERVICIOS Y CONTACTO */}
      <section className={styles.s3}>
        <div>
          <h2>Servicios</h2>
          <p>🔹 Consultas (Costo: $1,300)</p>
          <p>🔹 Peritajes (Costo: Según el caso en particular)</p>
          <p className={styles.subt}>Diagnósticos y tratamientos especializados en:</p>
          <ul>
            <li>Preocupaciones excesivas.</li>
            <li>Ánimo marcado por tristeza profunda (depresión).</li>
            <li>Eventos traumáticos.</li>
            <li>Pensamientos intrusivos.</li>
            <li>Miedos irracionales.</li>
            <li>Trastornos psicóticos.</li>
            <li>Déficit de atención.</li>
            <li>Anorexia y bulimia (problemas de alimentación).</li>
            <li>Problemas relacionados con sustancias.</li>
            <li>Problemas de personalidad.</li>
          </ul>

        </div>
        <div>
          <h2>Contacto</h2>
          <article>
            <p className={styles.subt2}>Teléfono / Whatsapp</p>
            <p>Principal: (222) 32 51 922</p>
            <p>Consultorio: (222) 36 11 3582</p>
            <article className={styles.imgs}>
              <a href="https://wa.me/2223251922">
                <img
                  src={`${process.env.PUBLIC_URL}/whatsapp.webp`}
                  alt="icono de Whatsapp"
                  className={styles.icon}
                />
              </a>
              <a href="tel:2223251922">
                <img
                  src={`${process.env.PUBLIC_URL}/cell.png`}
                  alt="Cell"
                />
              </a>
            </article>
          </article>
          <article>
            <p className={styles.subt2}>Dirección del consultorio📍</p>
            <p>Blvrd 22 sur</p>
            <p>Colonia Jardines de San Manuel</p>
            <p>#5314</p>
            <p>(Cita Previa)</p>
            <article className={styles.map}>
              <a href="https://www.google.com.mx/maps/place/Medical+%26+Stetic,+Blvrd+22+Sur+5314,+Fovissste+San+Manuel,+72570+Puebla,+Pue./@19.0138081,-98.1957707,19z/data=!4m14!1m7!3m6!1s0x85cfc09911276b57:0x761760cf7a296cc5!2sBlvrd+22+Sur+5314,+Jardines+de+San+Manuel,+72570+Puebla,+Pue.!8m2!3d19.0139826!4d-98.1958275!16s%2Fg%2F11c18ycqr7!3m5!1s0x85cfc09929671bdb:0x5c58aec8eb2e80fe!8m2!3d19.0139646!4d-98.195982!16s%2Fg%2F1ptwhlw38">
                <img
                  src={`${process.env.PUBLIC_URL}/mapa.png`}
                  alt="mapa de la ubicación del consultorio"
                  className={styles.icon}
                />
              </a>
            </article>
          </article>
          <article className={styles.doctoralia}>
            <a href="https://www.doctoralia.com.mx/veronica-funes/psiquiatra/puebla">
              <img
                src={`${process.env.PUBLIC_URL}/doctoralia.png`}
                alt="icono de Doctoralia"
                className={styles.icon}
              />
            </a>
          </article>
        </div>
      </section>

      {/* INFO ADICIONAL (EXPERIENCIA, ETC.) */}
      <ProfessionalInfo />
      <Buttons />
      {/* SECCIÓN DEL CALENDARIO AL FINAL */}
      <section className={styles.calendar}>
        <h1>Verifica disponibilidad</h1>
        <Container>
          <Calendar
            localizer={localizer}
            events={events}
            min={minTime}
            max={maxTime}
            messages={messages}
            views={['week', 'day', 'agenda']}
            defaultView="day"
            onView={(view) => setView(view)}
            eventPropGetter={eventPropGetter}
            components={{
              toolbar: CustomToolbar,
              event: (props) => <CustomEvent {...props} view={view} />,
            }}
          />
        </Container>
        <div className={styles.indicaters}>
          <div>
            <div className={`${styles.ball} ${styles.notAvailable}`}></div>
            <p>No disponible</p>
          </div>
          <div>
            <div className={`${styles.ball} ${styles.consequent}`}></div>
            <p>Primera vez o subsecuente</p>
          </div>
          <div>
            <div className={`${styles.ball} ${styles.subsequent}`}></div>
            <p>Solo subsecuente</p>
          </div>
        </div>
        <Link to="/agendar">
          <button className={styles.btn}>Reserva tu cita ahora</button>
        </Link>
      </section>

      <footer className={styles.footer}>© 2024 Dra. Verónica Funes Paz</footer>
    </div>
  );
};

export default Home;
