import React, { useEffect, useState } from 'react';
import moment from 'moment';

import 'moment/locale/es'; // Importar localización en español
import styles from './nuevo.module.css'; // Asegúrate de crear este archivo CSS
import { scheduleBuilder } from '../../helpers/builder';

import { adminHabiles } from '../../helpers/habiles'

import { uri } from '../../helpers/url'
import MessageModal from "../../components/resultado/resultado";

const Formulario = () => {

    function filtrarCitas(citas) {
        const hoy = moment().startOf('day'); // Fecha actual al inicio del día
        const dosMesesDespues = moment().add(2, 'months').endOf('day'); // Fecha de 2 meses en adelante

        return citas.filter(cita => {
            const fechaCita = moment(cita.start);
            return fechaCita.isBetween(hoy, dosMesesDespues, null, '[]'); // Incluye las citas de hoy y hasta 2 meses
        });
    }

    const [horario, setHorario] = useState([])



    moment.locale('es');

    useEffect(() => {
        const fetchData = async (enlace) => {
            try {
                const response = await fetch(enlace, {
                    method: 'GET',
                });

                if (!response.ok) {
                    throw new Error('Error de conexión. Asegúrate de que el servidor esté en ejecución.');
                }

                const jsonData = await response.json();

                if (jsonData.error) {
                    throw new Error(jsonData.error);
                } else {
                    console.log(jsonData);
                    const result = scheduleBuilder(adminHabiles(), filtrarCitas(jsonData));

                    setHorario(result)

                }
            } catch (error) {
                console.log('Connection error');
                console.error('Error fetching data:', error);
            }
        };

        fetchData(`${uri()}api/dates`);
    }, []);


    //Solo permite isertar numeros en el campo de telefono
    const [telefono, setTelefono] = useState("");

    const handleChange = (e) => {
        const valor = e.target.value;
        // Solo permite números
        if (/^\d*$/.test(valor)) {
            setTelefono(valor);
        }
    };


    //Solo permite isertar numeros en el campo de telefono
    const [edad, setEdad] = useState("");

    const handleChangeedad = (e) => {
        const valor = e.target.value;

        // Solo permite números de hasta 2 dígitos
        if (/^\d{0,2}$/.test(valor)) {
            setEdad(valor);
        }
    };

    const [fechaHoy] = useState(() => moment().format('YYYY-MM-DD'));

    const [tipoCita, setTipoCita] = useState("");
    const [horasDisponibles, setHorasDisponibles] = useState([]);
    const [intervalo, setIntervalo] = useState(0)
    const [diaSeleccionado, setDiaSeleccionado] = useState(null); // Cambiado a null
    const [horaInicio, setHoraInicio] = useState('')
    const [horaFin, setHoraFin] = useState('');


    const handleTipoCitaChange = (e) => {
        setTipoCita(e.target.value);
        if (diaSeleccionado) {
            generarHorariosDisponibles(e.target.value, diaSeleccionado);

            setHoraInicio("")
            setHoraFin("")
        }
    };

    const handleDiaChange = (e) => {
        setHoraInicio("")
        setHoraFin("")
        const dia = moment(e.target.value);
        console.log("Dia seleccionado: ", dia)
        console.log("Dia usando moment:", moment(dia).get('date'))
        const aux = moment(dia).toISOString();
        console.log("Probando algo: ", moment(aux))
        setDiaSeleccionado(dia);
        if (tipoCita) {
            generarHorariosDisponibles(tipoCita, dia);
        }
    };

    const generarHorariosDisponibles = (tipoCita, dia) => {
        const intervalo = tipoCita === "consecuente" ? 29 : 29;
        setIntervalo(intervalo)
        console.log("Intervalo seleccionado: ", intervalo)
        console.log("El dia es: ", dia)
        // Filtrar eventos disponibles para el día seleccionado
        const horarioDisponible = [{
            title: 'Primera vez',
            start: new Date(moment(dia).clone().hour(8).minute(0).second(0).millisecond(0).toDate()),
            end: new Date(moment(dia).clone().hour(20).minute(0).second(0).millisecond(0).toDate()),
            allDay: false,
            type: 'consecuente'
        }]

        console.log("Horario disponible: ", horarioDisponible)
        let horasDisponibles = [];

        // Recorremos los eventos disponibles
        horarioDisponible.forEach(evento => {
            let start = moment(evento.start).second(0).millisecond(0);
            let end = moment(evento.end).second(0).millisecond(0);

            // Generar las horas dentro del rango del evento según el intervalo
            while (start.isBefore(end)) {
                // Verificar si el intervalo cabe completamente dentro del rango
                if (start.clone().add(intervalo, 'minutes').isSameOrBefore(end)) {
                    horasDisponibles.push(start.format('YYYY-MM-DDTHH:mm'));
                }
                // Avanzar al siguiente intervalo

                start.add(intervalo + 1, 'minutes');

            }
        });

        setHorasDisponibles(horasDisponibles);
    };





    // Manejar cambios en el select de hora de inicio
    const handleHoraInicioChange = (e) => {
        const horaSeleccionada = e.target.value;
        setHoraInicio(horaSeleccionada);
        setHoraFin(moment(horaSeleccionada).add(intervalo, 'minutes').format("h:mm A"));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Crear un objeto JSON directamente desde el formulario
        const form = e.target;
        const json = Array.from(form.elements)
            .filter((element) => element.name) // Filtrar solo los elementos con el atributo `name`
            .reduce((acc, element) => {
                acc[element.name] = element.value;
                return acc;
            }, {});

        // Modificaciones específicas al JSON
        delete json.day;
        delete json.tipo;
        delete json.finish;
        if (json.notas === "") {
            json.notas = "Sin notas adicionales."
        }
        json.tipo = tipoCita;
        json.end = moment(horaInicio).add(intervalo, 'minutes').format('YYYY-MM-DDTHH:mm');

        console.log(json); // Muestra el objeto JSON en la consola

        fetch(uri() + 'api/add', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(json)
        })
            .then(response => {
                return response.json();
            })
            .then(data => {
                if (data.error) {
                    setModalConfig({
                        type: "error",
                        message: data.error,
                    });
                    setShowModal(true);
                    throw new Error(data.error);
                } else {
                    console.log(data);
                    setModalConfig({
                        type: "success",
                        message: data.message + "\nSu folio es: " + data.id,
                    });
                    setShowModal(true);
                }
            })
            .catch(error => {
                console.error(error);
            });
    };

    //Manejar cambios del selector de color
    const [colorSeleccionado, setColorSeleccionado] = useState("");
    const handleColorChange = (event) => {
        setColorSeleccionado(event.target.value);
    };

    const [showModal, setShowModal] = useState(false);
    const [modalConfig, setModalConfig] = useState({ type: "", message: "" });


    const isLightColor = (hexColor) => {
        // Convierte el hexadecimal a RGB
        const r = parseInt(hexColor.slice(1, 3), 16);
        const g = parseInt(hexColor.slice(3, 5), 16);
        const b = parseInt(hexColor.slice(5, 7), 16);

        // Calcula el brillo (YIQ)
        const yiq = (r * 299 + g * 587 + b * 114) / 1000;

        // Si el brillo es alto, es un color claro
        return yiq > 128;
    };



    return (
        <div className={styles.content}>
            <header className={styles.header}>
                <a href="/api" className={styles.backButton}>Atrás</a>
                <h1>Añadir nueva cita</h1>
            </header>
            <section className={styles.formulario}>
                <h1>Rellena tu información</h1>
                <form onSubmit={handleSubmit}>

                    <label htmlFor="nombre">Nombre completo:</label>
                    <input type="text" id="nombre" name="nombre" required />


                    <label htmlFor="edad">Edad:</label>
                    <input
                        type="text"
                        id="edad"
                        name="edad"
                        required
                        value={edad}
                        onChange={handleChangeedad}
                    />

                    <label htmlFor="telefono">Numero de telefono:</label>
                    <input
                        type="text"
                        id="numero"
                        name="numero"
                        required
                        value={telefono}
                        onChange={handleChange}
                    />

                    <label>Tipo de cita:</label>
                    <div>
                        <input
                            type="radio"
                            id="consecuente"
                            name="tipo"
                            value="consecuente"
                            onChange={handleTipoCitaChange}
                            required
                        />
                        <label htmlFor="consecuente">Primera vez</label>

                        <input
                            type="radio"
                            id="subsecuente"
                            name="tipo"
                            value="subsecuente"
                            onChange={handleTipoCitaChange}
                            required
                        />
                        <label htmlFor="subsecuente">Subsecuente</label>
                    </div>

                    <label htmlFor="day">Día de la cita:</label>
                    <input
                        type="date"
                        id="day"
                        name="day"
                        required
                        min={fechaHoy} // Fecha mínima establecida usando moment()
                        onChange={handleDiaChange}
                    />

                    <label htmlFor="start">Seleccione una hora de inicio</label>
                    <select
                        name="start"
                        id="start"
                        disabled={!diaSeleccionado || !tipoCita}
                        onChange={handleHoraInicioChange}
                        value={horaInicio} // Controla el valor seleccionado con el estado
                    >
                        <option value="" disabled>
                            Seleccione una hora
                        </option>
                        {horasDisponibles.map((hora) => (
                            <option key={hora} value={hora}>
                                {moment(hora).format('h:mm A')}
                            </option>
                        ))}
                    </select>


                    <label htmlFor="finish">Hora de fin:</label>
                    <input
                        type="text"
                        id="finish"
                        name="finish"
                        value={horaFin}
                        disabled
                    />


                    <label htmlFor="color">Elige un color:</label>
                    <select
                        id="color"
                        name="color"
                        required
                        onChange={handleColorChange}
                        value={colorSeleccionado}
                        style={{
                            backgroundColor: colorSeleccionado || "white", // Fondo basado en el color seleccionado
                            color: colorSeleccionado
                                ? isLightColor(colorSeleccionado) ? "black" : "white" // Contraste adecuado
                                : "black", // Negro si no hay color seleccionado
                        }}
                    >
                        <option value="" disabled>Selecciona un color</option>
                        <option value="#FF5733" style={{ backgroundColor: "#FF5733", color: "white" }}>Rojo Anaranjado</option>
                        <option value="#3498DB" style={{ backgroundColor: "#3498DB", color: "white" }}>Azul Brillante</option>
                        <option value="#2ECC71" style={{ backgroundColor: "#2ECC71", color: "white" }}>Verde Esmeralda</option>
                        <option value="#F1C40F" style={{ backgroundColor: "#F1C40F", color: "black" }}>Amarillo Dorado</option>
                        <option value="#8E44AD" style={{ backgroundColor: "#8E44AD", color: "white" }}>Morado Profundo</option>
                        <option value="#E67E22" style={{ backgroundColor: "#E67E22", color: "white" }}>Naranja Cálido</option>
                        <option value="#E91E63" style={{ backgroundColor: "#E91E63", color: "white" }}>Rosa Encendido</option>
                        <option value="#95A5A6" style={{ backgroundColor: "#95A5A6", color: "white" }}>Gris Suave</option>
                    </select>

                    <label htmlFor="notas">Notas:</label>
                    <textarea
                        id="notas"
                        name="notas"
                        rows="4"
                        placeholder="Escribe cualquier comentario adicional aquí"
                    />



                    <button type="submit">Agendar cita</button>
                </form>
            </section>
            {showModal && (
                <MessageModal
                    type={modalConfig.type}
                    message={modalConfig.message}
                    onClose={() => setShowModal(false)}
                    onSuccess={() => (window.location.href = "/api")}
                />
            )}
        </div>

    );
};



export default Formulario;
