import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/es'; // Importar localización en español
import 'react-big-calendar/lib/css/react-big-calendar.css';
import styles from './newapp.module.css'; // Asegúrate de crear este archivo CSS
import { scheduleBuilder } from '../../helpers/adminBuilder';
import Modal from "../../components/modal/Modal";
import styled from 'styled-components';


//Informacion
import { adminHabiles } from '../../helpers/habiles'
import { uri } from '../../helpers/url'

const Container = styled.div`
  height: 95vh;
  width: 90vw;
  .rbc-calendar{
    height:auto;
  }
  .rbc-event{
    width: 100% !important;   
    border-radius: 0px;
    border: none;
  }
  .rbc-time-content{
    overflow: hidden;
  }
  .rbc-events-container{
    margin-right:0;
  }
  .rbc-current-time-indicator{
    display: none;
  }
`;
const Create = () => {
  const [timeLeft, setTimeLeft] = useState(300); // 5 minutos en segundos

  // Lógica para el contador
  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft(prev => prev - 1);
      }, 1000);

      return () => clearInterval(timer); // Limpieza
    } else {
      window.location.reload(); // Refrescar la página al llegar a 0
    }
  }, [timeLeft]);

  // Formatear tiempo como mm:ss
  const formatTime = seconds => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };


  function filtrarCitas(citas) {
    const hoy = moment().startOf('day'); // Fecha actual al inicio del día
    const dosMesesDespues = moment().add(2, 'months').endOf('day'); // Fecha de 2 meses en adelante

    return citas.filter(cita => {
      const fechaCita = moment(cita.start);
      return fechaCita.isBetween(hoy, dosMesesDespues, null, '[]'); // Incluye las citas de hoy y hasta 2 meses
    });
  }

  const [horario, setHorario] = useState([])

  moment.locale('es');
  const localizer = momentLocalizer(moment);
  const messages = {
    allDay: 'Todo el día',
    previous: 'Atrás',
    next: 'Siguiente',
    today: 'Hoy',
    month: 'Mes',
    week: 'Semana',
    day: 'Día',
    agenda: 'Agenda',
    date: 'Fecha',
    time: 'Hora',
    event: 'Evento',
    noEventsInRange: 'No hay eventos en este rango',
    showMore: total => `+ Ver más (${total})`
  };
  const minTime = new Date();
  minTime.setHours(8, 0, 0);

  const maxTime = new Date();
  maxTime.setHours(20, 0, 0);
  // Función para ocultar domingos

  const [events, setEvents] = useState();
  const [view, setView] = useState('day');
  const CustomToolbar = ({ label, onNavigate, onView }) => {
    const capitalizeDay = (label) => {
      const [day, ...rest] = label.split(' ');
      return [day.charAt(0).toUpperCase() + day.slice(1), ...rest].join(' ');
    };
    return (
      <div className={styles.customtoolbar}>
        <span className="rbc-btn-group">
          <button type="button" className={styles.custombutton} onClick={() => onNavigate('PREV')}>
            Atrás
          </button>
          <button type="button" className={styles.custombutton} onClick={() => onNavigate('TODAY')}>
            Hoy
          </button>
          <button type="button" className={styles.custombutton} onClick={() => onNavigate('NEXT')}>
            Siguiente
          </button>
        </span>
        <span className={styles.customtoolbarlabel}>{capitalizeDay(label)}</span>
        <span className="rbc-btn-group">
          <button type="button" className={styles.custombutton} onClick={() => onView('week')}>
            Semana
          </button>
          <button type="button" className={styles.custombutton} onClick={() => onView('day')}>
            Día
          </button>
          {/* <button type="button" className={styles.custombutton} onClick={() => onView('agenda')}>
            Agenda
          </button> */}
        </span>
      </div>
    );
  };


  const CustomEvent = ({ event, title, view }) => {
    const [isPC, setIsPC] = useState(window.innerWidth > 768); // Ajuste basado en el ancho de pantalla

    useEffect(() => {
      const handleResize = () => {
        setIsPC(window.innerWidth > 768);
      };

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    const eventStyle = {
      borderRadius: '4px',
      border: 'none',
      display: isPC ? 'flex' : 'none', // Si es PC, display es flex, de lo contrario none
      alignItems: 'center',
      height: '100%',
    };

    if (view === 'week') {
      console.log(view);
      return (
        <div style={eventStyle}>
          {title}
        </div>
      );
    }

    return (
      <div style={eventStyle}>
        {title}
      </div>
    );
  };


  const eventPropGetter = (event) => {
    let backgroundColor = '';
    let fontSize = '';
    switch (event.type) {
      case 'red':
        backgroundColor = 'red';
        fontSize = "22px";
        break;
      default: backgroundColor = event.color;
    }
    return { style: { backgroundColor, fontSize } };
  };


  useEffect(() => {
    const fetchData = async (enlace) => {
      try {
        const response = await fetch(enlace, {
          method: 'GET',
        });

        if (!response.ok) {
          throw new Error('Error de conexión. Asegúrate de que el servidor esté en ejecución.');
        }

        const jsonData = await response.json();

        if (jsonData.error) {
          throw new Error(jsonData.error);
        } else {
          console.log(jsonData);
          // Guardar el resultado en una variable
          const result = scheduleBuilder(adminHabiles(), filtrarCitas(jsonData));

          // Si quieres actualizar el estado con este resultado
          setEvents(result);
          setHorario(result)
        }
      } catch (error) {
        console.log('Connection error');
        console.error('Error fetching data:', error);
      }
    };

    fetchData(`${uri()}api/dates`);
  }, []);



  //Funciones del modal
  const [modal, setModal] = useState(false);
  const [cita, setCita] = useState({});
  const handleSelectEvent = (e) => {
    if (e.title !== "No disponible") {
      setCita(e)
      console.log("Evento: ", e)
      setModal(true)
    }
  }
  const colorNames = [
    {
      color: "#3498DB",
      nombre: "Azul Brillante"
    },
    {
      color: "#2ECC71",
      nombre: "Verde Esmeralda"
    },
    {
      color: "#F1C40F",
      nombre: "Amarillo Dorado"
    },
    {
      color: "#8E44AD",
      nombre: "Morado Profundo"
    },
    {
      color: "#E67E22",
      nombre: "Naranja Cálido"
    },
    {
      color: "#E91E63",
      nombre: "Rosa Encendido"
    },
    {
      color: "#95A5A6",
      nombre: "Gris Suave"
    },
    {
      color: "#FF5733",
      nombre: "Rojo Anaranjado"
    }
  ]

  function getColorName(hex) {
    if (hex !== undefined) {
      const color = colorNames.find(c =>
        c.color.toUpperCase() === hex.toUpperCase()
      );
      return color ? color.nombre : "Color no encontrado";
    } else {
      return "";
    }


  }




  return (
    <div className={styles.content}>
      <Modal modal={modal} setModal={setModal}
        id={cita.id}
        inicio={cita.start}
        fin={cita.end}
        tipo={cita.type}
        nombre={cita.nombre}
        edad={cita.edad}
        numero={cita.numero}
        notas={cita.notas}
        colorName={getColorName(cita.color)}
        color={cita.color}
      ></Modal>
      <header className={styles.header}>
        <h1>Administrador</h1>
        <a href='/api/nuevo' className={styles.addButton}>Añadir cita</a>
      </header>

      <div className={styles.timer}>
        <p>Tiempo restante para refrescar: <strong>{formatTime(timeLeft)}</strong></p>
      </div>
      
      <section className={styles.calendarSection}>
        <Container>
        <Calendar
          onSelectEvent={handleSelectEvent}
          localizer={localizer}
          events={events}
          min={minTime}
          max={maxTime}
          messages={messages}
          views={['week', 'day', 'agenda']}
          defaultView='day'
          onView={(view) => setView(view)}
          eventPropGetter={eventPropGetter}
          components={{
            toolbar: CustomToolbar,
            event: (props) => <CustomEvent   {...props} view={view} />,
          }}
        />
        </Container>
      </section>
          
    </div>
  );
};



export default Create;
