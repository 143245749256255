import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./modal.module.css";
import moment from 'moment';
import 'moment/locale/es'; 
import { uri } from '../../helpers/url'

const Modal = (props) => {
  const navigate = useNavigate();
  // Estado para manejar la visibilidad del modal de confirmación
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const handleDelete = async () => {
    try {
      const respuesta = await fetch(uri() + "api/delete", {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ id: props.id }),
      });
  
      if (!respuesta.ok) {
        throw new Error("Error de conexión. Verifica que el servidor esté en ejecución.");
      }
  
      const datos = await respuesta.json();
      if (datos.error) {
        throw new Error(datos.error);
      }
  
      console.log("Cita eliminada:", datos);
  
      // Cierra el modal de confirmación y el modal principal después de la eliminación
      setShowDeleteConfirmation(false);
      props.setModal(false);
      window.location.reload();
    } catch (error) {
      console.error("Error al eliminar la cita:", error.message);
      alert("Hubo un problema al intentar eliminar la cita. Por favor, inténtalo nuevamente.");
    }
  };

  console.log("Los props en el modal: ", props)

  const handleEdit = () => {
    const citaData = {
      id: props.id,
      inicio: props.inicio,
      fin: props.fin,
      tipo: props.tipo,
      nombre: props.nombre,
      edad: props.edad,
      numero: props.numero,
      notas: props.notas,
      colorName: props.colorName,
      color: props.color,
    };
    navigate("/api/editar", { state: citaData });
  };

  if (props.modal) {
    return (
      <section className={styles.overlay}>
        <div className={styles.content}>


          {!showDeleteConfirmation ? (
            <>
              <button
                className={styles.closeButton}
                onClick={() => props.setModal(false)}
              >
                X
              </button>
              <h2>Detalles de la cita</h2>
              <div className={styles.infoGroup}>
                <label>Folio:</label>
                <input type="text" value={props.id} disabled />
              </div>
              <div className={styles.infoGroup}>
                <label>Fecha:</label>
                <input type="text" value={moment(props.inicio).format("DD [de] MMMM YYYY")} disabled />
              </div>
              <div className={styles.infoGroup}>
                <label>Inicio:</label>
                <input type="text" value={moment(props.inicio).format('hh:mm A')} disabled />
              </div>
              <div className={styles.infoGroup}>
                <label>Fin:</label>
                <input type="text" value={moment(props.fin).format('hh:mm A')} disabled />
              </div>
              <div className={styles.infoGroup}>
                <label>Tipo:</label>
                <input type="text" value={props.tipo} disabled />
              </div>
              <div className={styles.infoGroup}>
                <label>Nombre:</label>
                <input type="text" value={props.nombre} disabled />
              </div>
              <div className={styles.infoGroup}>
                <label>Edad:</label>
                <input type="text" value={props.edad} disabled />
              </div>
              <div className={styles.infoGroup}>
                <label>Teléfono:</label>
                <input type="text" value={props.numero} disabled />
              </div>
              <div className={styles.infoGroup}>
                <label>Notas:</label>
                <textarea value={props.notas} placeholder="Sin notas adicionales" disabled />
              </div>
              <div className={styles.infoGroup}>
                <label>Color de cita:</label>
                <input
                  type="text"
                  value={props.colorName}  // Mostrar el nombre del color
                  disabled
                  style={{
                    backgroundColor: props.color,  // Establecer el color de fondo
                    color: "#fff", // Asegurarse de que el texto sea legible sobre el color
                  }}
                />
              </div>
              <div className={styles.actions}>
                <button
                  className={styles.deleteButton}
                  onClick={() => setShowDeleteConfirmation(true)} // Mostrar modal de confirmación
                >
                  Eliminar cita
                </button>
                <button
                  className={styles.editButton}
                  onClick={handleEdit}
                >
                  Editar información
                </button>
              </div>
            </>
          ) : (
            // Modal de confirmación
            <div className={styles.confirmation}>
              <h3>¿Estás seguro de que deseas eliminar esta cita?</h3>
              <div className={styles.confirmationActions}>
                <button
                  className={styles.confirmButton}
                  onClick={handleDelete}
                >
                  Sí, eliminar
                </button>
                <button
                  className={styles.cancelButton}
                  onClick={() => setShowDeleteConfirmation(false)} // Volver a los detalles
                >
                  Cancelar
                </button>
              </div>
            </div>
          )}
        </div>
      </section>
    );
  } else {
    return null;
  }
};

export default Modal;



