import moment from 'moment';

function scheduleBuilder(diasHabiles, events) {
    const start = moment();
    const end = moment().add(2, 'months');
    var schedule = [];

    console.log("Dias habiles: ", diasHabiles);
    console.log("Citas: ", events);

    while (start <= end) {
        let dayAvailability;
        switch (start.day()) {
            case 0: // Domingo
                dayAvailability = null;
                break;
            default:
                dayAvailability = diasHabiles[start.day() - 1];
        }

        if (dayAvailability !== null) {
            // Filtrar citas que ocurren en el día actual del bucle
            const citasDelDia = events.filter(event =>
                moment(event.start).isSame(start, 'day')
            );

            // Ordenar las citas por hora de inicio
            citasDelDia.sort((a, b) => moment(a.start).diff(moment(b.start)));


            // Agregar las citas del día al cronograma
            citasDelDia.forEach(event => {
                schedule.push({
                    id: event.id,
                    title: event.nombre,
                    start: new Date(moment(event.start).toDate()),
                    end: new Date(moment(event.end).toDate()),
                    nombre: event.nombre,
                    numero: event.numero,
                    allDay: false,
                    type: event.tipo,
                    color: event.color,
                    colorName: event.colorName,
                    edad: event.edad,
                    notas: event.notas
                });
            });
        } else {
            // Día no disponible (domingo)
            schedule.push({
                title: 'No disponible',
                start: new Date(start.clone().hour(8).minute(0).second(0).millisecond(0).toDate()),
                end: new Date(start.clone().hour(20).minute(0).second(0).millisecond(0).toDate()),
                allDay: false,
                type: 'red'
            });
        }

        start.add(1, 'days');
    }

    return schedule;
}

export { scheduleBuilder };