import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { momentLocalizer } from 'react-big-calendar';
import 'moment/locale/es'; // Importar localización en español
import styles from './formulario.module.css'; // Asegúrate de crear este archivo CSS
import { scheduleBuilder } from '../../helpers/adminBuilder'


import { useLocation } from 'react-router-dom';


import { adminHabiles } from '../../helpers/habiles'
import { uri } from '../../helpers/url'
import MessageModal from "../resultado/resultado";

const Formulario = () => {
    const location = useLocation();
    const citaData = location.state || {
        inicio: "",
        fin: "",
        tipo: "consecuente",
        nombre: "",
        edad: "",
        numero: "",
        notas: "",
        color: "#FF5733",
    };

    function filtrarCitas(citas) {
        const hoy = moment().startOf('day'); // Fecha actual al inicio del día
        const dosMesesDespues = moment().add(2, 'months').endOf('day'); // Fecha de 2 meses en adelante

        return citas.filter(cita => {
            const fechaCita = moment(cita.start);
            return fechaCita.isBetween(hoy, dosMesesDespues, null, '[]'); // Incluye las citas de hoy y hasta 2 meses
        });
    }

    const [horario, setHorario] = useState([])




    moment.locale('es');


    useEffect(() => {
        const fetchData = async (enlace) => {
            try {
                const response = await fetch(enlace, {
                    method: 'GET',
                });

                if (!response.ok) {
                    throw new Error('Error de conexión. Asegúrate de que el servidor esté en ejecución.');
                }

                const jsonData = await response.json();

                if (jsonData.error) {
                    throw new Error(jsonData.error);
                } else {
                    console.log(jsonData);
                    // Guardar el resultado en una variable
                    const result = scheduleBuilder(adminHabiles(), filtrarCitas(jsonData));

                    setHorario(result)

                }
            } catch (error) {
                console.log('Connection error');
                console.error('Error fetching data:', error);
            }
        };

        fetchData(`${uri()}api/dates`);
    }, []);


    //Solo permite isertar numeros en el campo de numero
    const [numero, setnumero] = useState(citaData.numero);

    const handleChange = (e) => {
        const valor = e.target.value;
        // Solo permite números
        if (/^\d*$/.test(valor)) {
            setnumero(valor);
        }
    };

    //Solo permite isertar numeros en el campo de numero
    const [edad, setEdad] = useState(citaData.edad);

    const handleChangeedad = (e) => {
        const valor = e.target.value;

        // Solo permite números de hasta 2 dígitos
        if (/^\d{0,2}$/.test(valor)) {
            setEdad(valor);
        }
    };

    const [fechaHoy] = useState(() => moment().format('YYYY-MM-DD'));

    const [tipoCita, setTipoCita] = useState(citaData.tipo);


    const [horasDisponibles, setHorasDisponibles] = useState([]);
    const [intervalo, setIntervalo] = useState(0)
    const [diaSeleccionado, setDiaSeleccionado] = useState(moment(citaData.inicio).format('YYYY-MM-DD')); // Cambiado a null
    console.log("Dia defecto: ", diaSeleccionado)
    const diaOriginal = moment(citaData.inicio).format('YYYY-MM-DD');
    const [horaInicio, setHoraInicio] = useState(moment(citaData.inicio).format('YYYY-MM-DDTHH:mm'))
    const [horaFin, setHoraFin] = useState(moment(citaData.fin).format('hh:mm A'));

    useEffect(() => {
        if (diaSeleccionado && tipoCita) {
            generarHorariosDisponibles(tipoCita, diaSeleccionado); // Generar horarios al cargar el componente
        }
    }, [diaSeleccionado, tipoCita]); // Ejecutar solo cuando `diaSeleccionado` o `tipoCita` cambien

    const handleTipoCitaChange = (e) => {
        setTipoCita(e.target.value);
        if (diaSeleccionado) {
            generarHorariosDisponibles(e.target.value, diaSeleccionado);
            setHoraInicio("")
            setHoraFin("")
        }
    };

    const handleDiaChange = (e) => {
        setHoraInicio("")
        setHoraFin("")
        const dia = moment(e.target.value).format('YYYY-MM-DD');

        const aux = moment(dia).toISOString();
        setDiaSeleccionado(dia);
        if (tipoCita) {
            generarHorariosDisponibles(tipoCita, dia);
        }
    };

    const generarHorariosDisponibles = (tipoCita, dia) => {
        const intervalo = tipoCita === "consecuente" ? 59 : 29;
        setIntervalo(intervalo)

        // Filtrar eventos disponibles para el día seleccionado
        const horarioDisponible = [{
            title: 'Primera vez',
            start: new Date(moment(dia).clone().hour(8).minute(0).second(0).millisecond(0).toDate()),
            end: new Date(moment(dia).clone().hour(20).minute(0).second(0).millisecond(0).toDate()),
            allDay: false,
            type: 'consecuente'
        }]

        console.log("Horario disponible: ", horarioDisponible)
        let horasDisponibles = [];

        // Recorremos los eventos disponibles
        horarioDisponible.forEach(evento => {
            let start = moment(evento.start).second(0).millisecond(0);
            let end = moment(evento.end).second(0).millisecond(0);

            // Generar las horas dentro del rango del evento según el intervalo
            while (start.isBefore(end)) {
                // Verificar si el intervalo cabe completamente dentro del rango
                if (start.clone().add(intervalo, 'minutes').isSameOrBefore(end)) {
                    horasDisponibles.push(start.format('YYYY-MM-DDTHH:mm'));
                }

                start.add(intervalo + 1, 'minutes');

            }
        });

        setHorasDisponibles(horasDisponibles);
    };


    // Manejar cambios en el select de hora de inicio
    const handleHoraInicioChange = (e) => {
        const horaSeleccionada = e.target.value;
        setHoraInicio(horaSeleccionada);
        setHoraFin(moment(horaSeleccionada).add(intervalo, 'minutes').format("h:mm"));
    };


    //Manejar cambios del selector de color
    const [colorSeleccionado, setColorSeleccionado] = useState(citaData.color);
    const handleColorChange = (event) => {
        setColorSeleccionado(event.target.value);
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        const form = e.target;
        const json = Array.from(form.elements)
            .filter(element => element.name)
            .reduce((acc, element) => {
                acc[element.name] = element.value;
                return acc;
            }, {});
        // Modificaciones específicas al JSON
        delete json.finish;
        if (json.notas === "") {
            json.notas = "Sin notas adicionales"
        }
        delete json.day;
        json.tipo = tipoCita;
        json.end = moment(horaInicio).add(intervalo, 'minutes').format('YYYY-MM-DDTHH:mm');

        if (diaOriginal !== diaSeleccionado) {
            const jsonCopy = { ...json };
            jsonCopy.color = "#95A5A6";
            jsonCopy.start = moment(citaData.inicio).format('YYYY-MM-DDTHH:mm');
            jsonCopy.end = moment(citaData.fin).format('YYYY-MM-DDTHH:mm');
            jsonCopy.id = citaData.id;
            const elemento = {
                id: citaData.id
            };

            console.log("Este es el elemento: ", elemento)
            try {
                // Eliminar la cita anterior
                await enviarPeticion(`${uri()}api/delete`, 'DELETE', elemento);

                // Crear nueva cita
                await enviarPeticion(`${uri()}api/add`, 'POST', jsonCopy);


                // Crear nueva cita
                const respuesta = await enviarPeticion(`${uri()}api/add`, 'POST', json);

                // Manejo de respuesta
                setModalConfig({
                    type: respuesta.error ? "error" : "success",
                    message: respuesta.error || "Cita editada con exito el folio es: " + respuesta.id,
                });
                setShowModal(true);
            } catch (error) {
                console.error("Error en la operación:", error);
                setModalConfig({
                    type: "error",
                    message: error.message,
                });
                setShowModal(true);
            }
        } else {
            json.id = citaData.id;
            const elemento = {
                id: citaData.id
            };

            console.log("Este es el elemento: ", elemento)
            try {
                // Eliminar la cita anterior
                await enviarPeticion(`${uri()}api/delete`, 'DELETE', elemento);

                // Crear nueva cita
                const respuesta = await enviarPeticion(`${uri()}api/add`, 'POST', json);

                // Manejo de respuesta
                setModalConfig({
                    type: respuesta.error ? "error" : "success",
                    message: respuesta.error
                        ? respuesta.error
                        : respuesta.id === citaData.id
                        ? "Cita editada con éxito"
                        : "Cita editada con éxito, el nuevo folio es: " + respuesta.id,
                });
                setShowModal(true);
            } catch (error) {
                console.error("Error en la operación:", error);
                setModalConfig({
                    type: "error",
                    message: error.message,
                });
                setShowModal(true);
            }
        }


    };

    // Función para enviar peticiones HTTP
    const enviarPeticion = async (url, metodo, cuerpo) => {
        const respuesta = await fetch(url, {
            method: metodo,
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(cuerpo),
        });

        if (!respuesta.ok) {
            throw new Error('Error de conexión. Verifica que el servidor esté en ejecución.');
        }

        const datos = await respuesta.json();
        if (datos.error) {
            throw new Error(datos.error);
        }
        return datos;
    };






    const [showModal, setShowModal] = useState(false);
    const [modalConfig, setModalConfig] = useState({ type: "", message: "" });


    return (
        <div className={styles.content}>
            <header className={styles.header}>
                <a href="/api" className={styles.backButton}>Atrás</a>
                <h1>Editar información</h1>
            </header>
            <section className={styles.formulario}>
                <h1>Rellena tu información</h1>
                <form onSubmit={handleSubmit}>
                    <label htmlFor="nombre">Nombre completo:</label>
                    <input type="text" id="nombre" name="nombre" required defaultValue={citaData.nombre} />



                    <label htmlFor="edad">Edad:</label>
                    <input
                        type="text"
                        id="edad"
                        name="edad"
                        required
                        value={edad}
                        onChange={handleChangeedad}
                    />

                    <label htmlFor="numero">Número de teléfono:</label>
                    <input
                        type="text"
                        id="numero"
                        name="numero"
                        required
                        value={numero}
                        onChange={handleChange}
                    />

                    <label>Tipo de cita:</label>
                    <div>
                        <input
                            type="radio"
                            id="consecuente"
                            name="tipo"
                            value="consecuente"
                            onChange={handleTipoCitaChange}
                            checked={tipoCita === "consecuente"}
                            required
                        />
                        <label htmlFor="consecuente">Primera vez</label>

                        <input
                            type="radio"
                            id="subsecuente"
                            name="tipo"
                            value="subsecuente"
                            onChange={handleTipoCitaChange}
                            checked={tipoCita === "subsecuente"}
                            required
                        />
                        <label htmlFor="subsecuente">Subsecuente</label>
                    </div>

                    <label htmlFor="day">Día de la cita:</label>
                    <input
                        type="date"
                        id="day"
                        name="day"
                        required
                        value={diaSeleccionado} // Fecha de ejemplo
                        min={fechaHoy}
                        onChange={handleDiaChange}
                    />

                    <label htmlFor="init-time">Seleccione una hora de inicio</label>
                    <select
                        name="start"
                        id="start"
                        disabled={!diaSeleccionado || !tipoCita}
                        onChange={handleHoraInicioChange}
                        value={horaInicio} // Controla el valor seleccionado con el estado
                    >
                        <option value="" disabled>
                            Seleccione una hora
                        </option>

                        {horasDisponibles.map((hora) => (
                            <option key={hora} value={hora}>
                                {moment(hora).format('hh:mm A')}
                            </option>
                        ))}
                    </select>

                    <label htmlFor="finish">Hora de fin:</label>
                    <input
                        type="text"
                        id="finish"
                        name="finish"
                        value={horaFin} // Hora de fin por defecto
                        disabled
                    />

                    <label htmlFor="color">Elige un color:</label>
                    <select
                        id="color"
                        name="color"
                        required
                        onChange={handleColorChange}
                        value={colorSeleccionado} // Rojo anaranjado por defecto
                        style={{
                            backgroundColor: colorSeleccionado, // Fondo basado en el color seleccionado
                            color: "white",
                        }}
                    >
                        <option value="" disabled>Selecciona un color</option>
                        <option value="#FF5733" style={{ backgroundColor: "#FF5733", color: "white" }}>Rojo Anaranjado</option>
                        <option value="#3498DB" style={{ backgroundColor: "#3498DB", color: "white" }}>Azul Brillante</option>
                        <option value="#2ECC71" style={{ backgroundColor: "#2ECC71", color: "white" }}>Verde Esmeralda</option>
                        <option value="#F1C40F" style={{ backgroundColor: "#F1C40F", color: "black" }}>Amarillo Dorado</option>
                        <option value="#8E44AD" style={{ backgroundColor: "#8E44AD", color: "white" }}>Morado Profundo</option>
                        <option value="#E67E22" style={{ backgroundColor: "#E67E22", color: "white" }}>Naranja Cálido</option>
                        <option value="#E91E63" style={{ backgroundColor: "#E91E63", color: "white" }}>Rosa Encendido</option>
                        <option value="#95A5A6" style={{ backgroundColor: "#95A5A6", color: "white" }}>Gris Suave</option>
                    </select>

                    <label htmlFor="notas">Notas:</label>
                    <textarea
                        id="notas"
                        name="notas"
                        rows="4"
                        placeholder="Escribe cualquier comentario adicional aquí"
                        defaultValue={citaData.notas} // Valor predeterminado de las notas
                    />

                    <button type="submit">Editar cita</button>
                </form>
            </section>
            {showModal && (
                <MessageModal
                    type={modalConfig.type}
                    message={modalConfig.message}
                    onClose={() => setShowModal(false)}
                    onSuccess={() => (window.location.href = "/api")}
                />
            )}
        </div>
    );

};



export default Formulario;
