import React from 'react';
import styled from 'styled-components';
import { FaPhone, FaWhatsapp, FaCalendarAlt } from 'react-icons/fa';

// Paleta de colores
// #FAF6E3 (más claro)
// #D8DBBD
// #B59F78
// #2A3663 (más oscuro)

// Contenedor principal
const Container = styled.div`
  background-color: #FAF6E3;
  border: 2px solid #D8DBBD;
  padding: 2rem;
  border-radius: 8px;
  max-width: 600px;
  margin: 1rem auto;
  font-family: Arial, sans-serif;
  color: #2A3663;
`;

// Título
const Title = styled.h2`
  margin-bottom: 1.5rem;
  color: #2A3663;
  text-align: center;
`;

// Texto descriptivo
const Text = styled.p`
  margin-bottom: 1rem;
  line-height: 1.5;
`;

// Lista ordenada para enumerar las formas
const List = styled.ol`
  margin-left: 1.5rem;
  margin-bottom: 1rem;
`;

// Contenedor de botones
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 1rem 0;
`;

// Botón genérico modificado para ocupar 75% de ancho, centrado y en bloque
const Button = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  padding: 0.75rem 1.25rem;
  background-color: #B59F78;
  color: #FAF6E3;
  border-radius: 4px;
  font-weight: bold;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  width: 75%;
  margin: 0 auto;
  
  &:hover {
    background-color: #2A3663;
    color: #FAF6E3;
  }

  svg {
    margin-right: 0.5rem;
  }
`;

// Nota adicional mejorada para aclarar la condición de la cita en línea
const Note = styled.span`
  display: block;
  margin-top: 0.5rem;
  font-style: italic;
  font-weight: bold;
  text-decoration: underline;
  color: #B59F78;
  font-size: 0.9rem;
`;

const Buttons = () => {
  return (
    <Container>
      <Title>Agenda tu cita</Title>
      <Text>
        Existen <strong>tres formas</strong> de agendar una cita:
      </Text>
      <List>
        <li>Por llamada telefónica</li>
        <li>Por Whatsapp</li>
        <li>
          Agendando directamente en el calendario en línea
          <Note>
            (Solo se permite una única cita en línea por paciente)
          </Note>
        </li>
      </List>
      <Text>
        Además, recibirás una llamada de confirmación junto con un folio para esta cita.
      </Text>
      <ButtonContainer>
        <Button href="tel:+522223251922">
            <FaPhone />
            Iniciar llamada
        </Button>
        <Button href="https://wa.me/522223251922" target="_blank" rel="noreferrer">
            <FaWhatsapp />
            Iniciar WhatsApp
        </Button>

        {/* Ajusta el href a la URL donde puedan agendar en el calendario */}
      </ButtonContainer>
    </Container>
  );
};

export default Buttons;
